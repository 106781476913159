@import url(https://fonts.googleapis.com/css?family=Work+Sans);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: "Work Sans", cursive;
  background-color: #002b3d;
  background-image: url(/static/media/bg-family.6652093d.png);
  background-size: cover;
  background-repeat: no-repeat;
}
html {
  padding: 0;
  margin: 0;
}
.body-style {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 56.25rem;
}
.main-logo-container {
  display: flex;
  align-items: center;
  padding: 2rem 0;
  flex-direction: column;
}
h2 {
  color: #ffffff;
  text-align: center;

  margin-top: 2rem;
  margin-bottom: 0;
}
p {
  color: #ffffff;
  text-align: center;
  font-size: 0.75rem;
  width: 21.875rem;
}
span {
  font-family: "Work Sans", cursive;
  color: #ffffff;
  font-size: 0.75rem;
  padding-top: 5px;
}
.card-container {
  display: flex;
  width: 31.25rem;
  justify-content: center;
  align-items: center;
}
.card {
  display: flex;
  flex-direction: column;
  height: 21.875rem;
  background: #ffffff;
  box-shadow: 0px 4.26537px 30px rgba(0, 0, 0, 0.5);
  border-radius: 1.875rem;
  padding: 2rem 3.125rem;
  justify-content: space-evenly;
}
.title {
  text-align: center;
  color: #015478;
}
.ospuncpba-main-description {
  color: #ffffff;
  text-align: center;
  font-size: 0.75rem;
  width: 21.875rem;
}
.select-style {
  border: 1px solid #dddddd;
    height: 2.8125rem;
    border-radius: 0.625rem;
    align-items: center;
    padding: 5px;
    font-weight: 700;
    font-family: "Work Sans", cursive;
    color: #015478;
    text-align-last: center;
}
.select-text {
  text-align: center;
  font-family: "Work Sans", cursive;
  color: #015478;
}
.input-style {
  border: 1px solid #dddddd;
  height: 1.875rem;
  border-radius: 0.625rem;
  padding: 5px;
  font-weight: 700;
    font-family: "Work Sans", cursive;
    color: #015478;
  text-align: center;
}
button:focus {
  outline: none;
}
.buttons-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.button-cancel {
  width: 45%;
  background: #ffffff;
  border: 2px solid #015478;
  box-sizing: border-box;
  border-radius: 10px;
  font-size: 1rem;
  padding: 10px;
  color: #015478;
  cursor: pointer;
}
.button-confirm {
  width: 45%;
  background: #015478;
  border: none;
  box-sizing: border-box;
  border-radius: 10px;
  font-size: 1rem;
  padding: 10px;
  color: #ffffff;
  cursor: pointer;
}
.button-disabled {
  width: 45%;
  background: #dadada;
  border: none;
  box-sizing: border-box;
  border-radius: 10px;
  font-size: 1rem;
  padding: 10px;
  color: white;
}
.footer-icons-style {
  width: 15.625rem;
  padding: 1rem 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.red-cosun {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.expired-container {
  justify-content: center;
  align-items: center;
}
.expired-text-style {
  font-size: 1.2rem;
  width: 90%;
  color: #015478;
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 0;
  border-bottom: 1px solid #ed8845;
  padding-bottom: 3rem;
}
.access-not-allow-title {
  color: #015478;
  text-align: center;
  font-size: 1.5rem;
  margin-top: 2rem;
  margin-bottom: 0;
  font-weight: 700;  
}
.access-not-allow-description {
  font-size: 1.2rem;
    width: 75%;
    color: #015478;
    text-align: center;
    margin-bottom: 0;
    border-bottom: 1px solid #ed8845;
    padding-bottom: 3rem;
}

/* AffiliateCardShared styles */
.credential-card {
  display: flex;
  flex-direction: column;
  height: 28.875rem;
  background: #ffffff;
  box-shadow: 0px 4.26537px 30px rgba(0, 0, 0, 0.5);
  border-radius: 1.875rem;
  padding: 0rem 3.125rem;
  justify-content: center;
  align-items: center;
}
.credential-card-container {
  display: flex;
  width: 90%;
  height: 300px;
  background-image: linear-gradient(123.76deg, #015478 59.39%, #6ac5da 131.81%);
  background-size: cover;
  border-radius: 1rem;
  box-shadow: 0px 4.26537px 30px rgba(0, 0, 0, 0.5);
}
.credential-card-affiliate-container {
  display: flex;
  flex-direction: column;
  margin: 0 0 0 1rem;
  justify-content: space-between;
  width: 95%;
}
.credential-title {
  color: #015478;
  margin: 0;
  font-size: 1.25rem;
  font-weight: 600;
  text-align: center;
  padding: 1rem 0;
}
.credential-container-subtitles {
  display: flex;
  width: 100%;
  justify-content: space-around;
  padding-bottom: 0.31rem;
}
.credential-subtitle {
  color: #015478;
  font-size: 1rem;
  line-height: 1.63rem;
  text-align: center;
}

.credential-card-cod-affiliate {
  font-size: 1.25rem;
  text-align: left;
  margin: 1rem 0 0 0;
  padding-bottom: 5px;
  color: #ffffff;
}
.credential-card-name-affiliate {
  font-size: 1.25rem;
  text-align: left;
  margin: 0;
  font-weight: 600;
  color: #ffffff;
}
.separator {
  display: flex;
  width: 80%;
  margin-top: 2rem;
  border-bottom: 1.12px solid #ed8845;
}
.ospuncpba-orange-container {
  display: flex;
  flex-direction: column;
  width: 10rem;
}
.ospuncpba-orange-text {
  text-align: center;
  height: 1rem;
  font-size: 5px;
  color: #ffffff;
}
.exactas-logo-red-cosun-container {
  display: flex;
  align-items: center;
  width: 90%;
  justify-content: space-between;
  padding-bottom: 1rem;
}
.red-cosun-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.red-cosun-logo {
  width: 4.5rem;
  height: 1.5rem;
}
.credential-card-footer-container {
  display: flex;
  padding: 1rem;
}
.credential-card-footer-text {
  font-style: normal;
  font-weight: normal;
  font-size: 0.88rem;
  line-height: 0.88rem;
  text-align: center;
  color: #015478;
}
.error-message {
  display: flex;
  color: red;
  text-align: center;
  padding: 10px 0;
}

@media screen and (max-width: 1280px) {
   
  .body-style {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 42rem;
  }
}
/* On screens that are 600px or less */
@media screen and (max-width: 600px) {
  body {
    font-family: "Work Sans", cursive;
    background-image: url(/static/media/bg-family.6652093d.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-color: rgb(1 30 43);
  }
  .ospuncpba-main-description {
    width: 19.875rem;
  }
  .card-container {
    display: flex;
    width: 18.75rem;
    justify-content: center;
    align-items: center;
  }
  .card {
    background: #ffffff;
    display: flex;
    flex-direction: column;
    height: 18.75rem;
    box-shadow: 0px 4.26537px 30px rgba(0, 0, 0, 0.5);
    border-radius: 1.875rem;
    padding: 2rem;
    justify-content: space-evenly;
  }
  .credential-card {
    display: flex;
    flex-direction: column;
    height: 28.875rem;
    background: #ffffff;
    box-shadow: 0px 4.26537px 10px rgba(0, 0, 0, 0.5);
    border-radius: 1rem;
    padding: 0;
    justify-content: center;
    align-items: center;
  }
  .ospuncpba-main-logo-responsive {
    width: 12.5rem;
  }
  .uni-logo {
    width: 3.13rem;
  }
  .red-cosun-logo {
    width: 4.1rem;
    height: 1.5rem;
  }
  .expired-text-style {
    font-size: 14px;
    width: 100%;
    color: #015478;
    text-align: center;
    margin-top: -1rem;
    margin-bottom: 0;
    border-bottom: 1px solid #ed8845;
    padding-bottom: 2rem;
  }
  .warn-logo {
    width: 3.75rem;
  }
  .access-not-allow-title {
    color: #015478;
    text-align: center;
    font-size: 18px;    
  }
}

/* spinner */
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

